import React from "react";

import { Breadcrumb, UCCardLong } from "../components";
import { UCCardLongShimmer } from "../shimmers/ucCardLongShimmer";

const UpcomingEvents = props => {
  const { data } = props;

  const breadcrumbData = [
    {
      id: 1,
      name: "Home",
      link: "/",
    },
    {
      id: 2,
      name: "Events",
    },
  ];

  return (
    <div className="detail">
      <div className="container">
        <Breadcrumb data={breadcrumbData} />
        <h3 className="detail__title">Events</h3>
        <div className="row">
          <div className="col-12">
            {data ? (
              data?.map(item => <UCCardLong key={item?.uuid} data={item} />)
            ) : (
              <UCCardLongShimmer />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvents;
