import React from "react";
import Shimmer from "react-shimmer-effect";

const UCCardLongShimmer = props => {
  return (
    <div className="uccard">
      <div className="uccard__initial">
        <Shimmer>
          <h3 className="uccard__initial-date uccard__shimmer-date"></h3>
        </Shimmer>
        <Shimmer>
          <p className="uccard__initial-place uccard__shimmer-place"></p>
        </Shimmer>
      </div>
      <div className="uccard__final">
        <Shimmer>
          <span className="uccard__final-title uccard__shimmer-title"></span>
        </Shimmer>
        <Shimmer>
          <p className="uccard__final-desc uccard__shimmer-desc"></p>
        </Shimmer>
      </div>
    </div>
  );
};

export { UCCardLongShimmer };
